<template>
  <div class="brands-list">
    <UiBanner fluid :title="$t('brands.list.title')" :breadcrumbs="breadcrumbs" />

    <UiContainer no-padding>
      <div class="brands-list__table-container">
        <v-data-table
          style="cursor: pointer"
          class="brands-list__table-container__table"
          height="100%"
          item-key="id"
          fixed-header
          :headers="headers"
          :items="reviews"
          :options.sync="options"
          :loading="refreshing"
          :server-items-length="reviewsPagination.total"
          :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
          @click:row="editBrand"
        >
          <template v-for="field in headers" v-slot:[`item.${field.value}`]="{ item }">
            <div :key="`customField ${field.value}`" v-if="field.customDisplay">
              <v-icon v-if="item.status[field.value] === 'valid'" color="success">{{ icons.mdiCheckCircle }}</v-icon>
              <v-icon v-else-if="item.status[field.value] === 'default_location_valid'" color="warning">
                {{ icons.mdiStoreMarker }}
              </v-icon>
              <v-icon v-else-if="item.status[field.value] === 'default_brand_valid'" color="warning">
                {{ icons.mdiOfficeBuildingMarker }}
              </v-icon>
              <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
            </div>
            <v-btn
              color="primary"
              :to="{ name: 'Brand', params: { id: item.id, currentTab: 'reviews' } }"
              :key="`btnField ${field.value}`"
              v-else-if="field.value === 'action'"
              icon
            >
              <v-icon>{{ icons.mdiClipboardEdit }}</v-icon>
            </v-btn>
            <div :key="`valueField ${field.value}`" v-else>
              {{ item[field.value] }}
            </div>
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiCheckCircle, mdiCloseCircle, mdiClipboardEdit, mdiOfficeBuildingMarker, mdiStoreMarker } from '@mdi/js'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'ReviewsList',
  components: {
    UiBanner,
    UiContainer,
  },
  data: () => ({
    icons: {
      mdiCheckCircle,
      mdiCloseCircle,
      mdiClipboardEdit,
      mdiStoreMarker,
      mdiOfficeBuildingMarker,
    },
    refreshing: false,
    search: '',
    options: {
      itemsPerPage: 25,
    },
  }),
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadReviewsPage()
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      reviews: state => state.brand.reviews,
      reviewsPagination: state => state.brand.reviewsPagination,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('brands.list.name'),
          to: {
            name: 'brandsList',
          },
        },
      ]
    },
    headers() {
      return [
        {
          text: this.$t('brands.reviewsList.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('brands.reviewsList.headers.pageTitle'),
          value: 'page_title',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.description'),
          value: 'description',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.seoTitle'),
          value: 'seo_title',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.seoDescription'),
          value: 'seo_description',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.metaTitle'),
          value: 'meta_title',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.metaDescription'),
          value: 'meta_description',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.backgroundPicture'),
          value: 'background_picture',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.logoUrl'),
          value: 'logo_url',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.footer'),
          value: 'footer',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.footerSocial'),
          value: 'footer_social',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.header'),
          value: 'header',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.hotPictures'),
          value: 'hot_pictures',
          customDisplay: true,
        },
        {
          text: this.$t('brands.reviewsList.headers.theme'),
          value: 'theme',
          customDisplay: true,
        },
        {
          value: 'action',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getReviews: 'brand/getReviews',
      setAlert: 'backoffice/setAlert',
    }),
    editBrand(brand) {
      this.$router.push({ name: 'Brand', params: { id: brand.id, currentTab: 'reviews' } })
    },
    async loadReviewsPage() {
      this.refreshing = true
      const { page, itemsPerPage, sortBy, sortDesc } = this.options
      try {
        await this.getReviews({ page, itemsPerPage, sortBy, sortDesc, search: this.search ? this.search : null })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
      this.refreshing = false
    },
  },
}
</script>

<style lang="scss">
.brands-list {
  .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
    width: 50px;
    max-width: 50px;
  }
}
</style>

<style lang="scss" scoped>
.table-cursor tbody tr:hover {
  cursor: pointer;
}

.brands-list {
  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
